<template>
	<v-container
			fluid
	>
		<v-card>
			<v-card-text>
				<GeneralInput ref="newCategory" :auto-close="false" title="New Category" :inputs="[{ label: `Name`, id: `name`, rules: `required` }]" />
				<Confirm ref="confirm" />
				<v-btn
					@click="newCategory"
					small
				>
					New Category
				</v-btn>

				<v-data-table
					:loading="loading"
					:headers="headers"
					:items="items"
					:server-items-length="totalItems"
					:search="search"
					:options.sync="options"
					item-key="id"
					class="row-pointer"
					:footer-props="{'items-per-page-options':[10,25,50]}"
				>
					<template v-slot:top>
						<v-row no-gutters>
							<v-col cols="12">
								<v-text-field no-gutters clearable v-model="search" label="Search" />
							</v-col>
						</v-row>

					</template>
					<template v-slot:[`item.name`]="{ item }">
						{{ item.name }} <span v-if="item.source">({{item.source}})</span>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-btn
							icon
							small
							@click="deleteCategory(item)"
						>
							<svg-icon icon="cross-circle" />
						</v-btn>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
	import GeneralInput from "@/components/dialogs/GeneralInput"
	import Confirm from "@/components/material/Confirm"

	export default {
		components: {
			GeneralInput,
			Confirm,
		},
		data () {
			return {
			loading: false,
			items: [],
			search: ``,
			options: {
				sortDesc: [false],
				sortBy: [`created`],
			},
			headers: [
				{ text: `Name`, value: `name` },
				{ text: ``, value: `actions` },
			],
			totalItems: 0,
			type: null,
			level: null,
			}
		},
		methods: {
			deleteCategory(o) {
				this.$refs.confirm.open(`Delete Category`, `Do you want to delete "${o.name}"?`, `confirm`)
					.then(confirm => {
						if (!confirm) return
						this.$httpInt.delete(`/v2/app/categories/${o.name}`)
							.then(() => this.refreshContent())
					})
			},
			newCategory() {
				this.$refs.newCategory.open()
					.then(res => {
						this.$httpInt.post(`/v2/app/categories/`, { name: res.name })
							.then(() => {
								this.$refs.newCategory.close()
								this.refreshContent()
							})
					})
			},
			refreshContent () {
				this.$httpInt.get(`/v2/app/categories`, { params: this.$paginationParams(this) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
					.finally(() => this.loading = false)
			},
		},
		name: `CommunityCategories`,
		watch: {
			search: {
				handler (value) {
					if (value && value.length < 4) return
					this.$debounce(() => this.refreshContent(), 300)
				},
				deep: true,
			},
			options: {
				handler () {
					this.refreshContent()
				},
				deep: true,
			},
		},
	}
</script>
