<template>
	<v-dialog v-model="show" width="400" @keydown.esc="cancel">
		<v-card>
			<template v-if="title">
				<v-card-title>
					{{ title }}
				</v-card-title>
				<v-divider class="pb-2" />
			</template>

			<template v-if="body">
				<v-card-text>
					{{ body }}
				</v-card-text>
			</template>

			<ValidationObserver
				ref="generalForm"
				@submit.prevent="accept"
			>
				<form autocomplete="off">
					<v-card-text class="pa-6 pt-0">
						<v-row v-for="input in inputs" :key="input.label">
							<v-col>
								<VTextFieldValidation
									v-model="form[input.id]"
									:label="input.label"
									:rules="input.rules"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="pb-0">
								<v-spacer />
								<Button
									type="submit"
								>
									{{ saveButton }}
								</Button>
							</v-col>
						</v-row>
					</v-card-text>
				</form>
			</ValidationObserver>

		</v-card>

	</v-dialog>
</template>

<script>
import VTextFieldValidation from '@/components/validation/VTextField.vue'

export default {
	components: {
		VTextFieldValidation,
	},
	data () {
		return {
			show: false,
			form: [],
		}
	},
	name: `GeneralInput`,
	methods: {
		open() {
			this.show = true
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		close() {
			this.reset()
		},
		accept() {
			this.$refs.generalForm.validate().then(valid => {
				if (!valid) return

				this.resolve(this.form)
				if (this.autoClose) this.close()
			})
		},
		cancel() {
			this.reject()
			this.close()
		},

		reset() { Object.assign(this.$data, this.$options.data.apply(this)) },
	},
	props: {
		autoClose: {
			type: Boolean,
			default: true,
		},
		title: {
			type: String,
			default: null,
		},
		body: {
			type: String,
			default: null,
		},
		saveButton: {
			type: String,
			default: `Ok`,
		},

		inputs: {
			type: Array,
			default: function () {
				return [
					{
						label: `Value`,
						id: `default`,
						rules: ``,
					}
				]
			}
		},
	},
}
</script>